
import ReloadButton from '@/components/BaseReloadButton.vue';
import FileViewer from '@/components/FileViewer.vue';
import Grid from '@/components/Grid.vue';
import GridFileItem from '@/components/GridFileItem.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import SortSelector from '@/components/SortSelector.vue';
import { File } from '@/interfaces/file.interface';
import { LocalFileActions } from '@/store/local-file/actions.enum';
// eslint-disable-next-line import/order
import Vue from 'vue';
// eslint-disable-next-line import/order
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'LocalFiles',

  components: {
    Grid,
    GridFileItem,
    LoadingSpinner,
    SortSelector,
    FileViewer,
    ReloadButton,
  },

  data() {
    return { showFileDialog: false, item: null as null | File };
  },

  computed: {
    ...mapGetters('localFile', { items: 'items', loading: 'loading' }),
  },

  created() {
    this.listDir();
  },

  watch: {
    showFileDialog(newValue) {
      if (!newValue && this.item) {
        URL.revokeObjectURL(this.item.location);
      }
    },
  },

  methods: {
    ...mapActions('localFile', {
      changeDir: LocalFileActions.CHANGE_DIR,
      listDir: LocalFileActions.LIST_DIR,
      getLocalFileUrl: LocalFileActions.OPEN_FILE,
    }),

    async openLocalFile(item: File) {
      const { localUrl } = await this.getLocalFileUrl(item.id);

      this.item = { ...item, location: localUrl };
      this.showFileDialog = true;
    },
  },
});
